import { FC, ReactNode, useCallback } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ReadMore } from "@mui/icons-material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { default as MinorIcon } from "@mui/icons-material/KeyboardArrowUp";
import { default as MajorIcon } from "@mui/icons-material/KeyboardDoubleArrowUp";
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Avatar as MuiAvatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  SvgIcon,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";

import styled from "@emotion/styled";

import { ColoredAvatarProps } from "components/notifications/types";
import ResourceGuard from "components/resourceGuard/ResourceGuard";

import { alarmEntityLocations } from "pages/alarms/constants";
import { AcknowledgeAlarmIcon } from "pages/alarms/img";

import useUserPreferencesStore from "hooks/useUserPreferences";

import { alarmApi } from "fetch/alarm";
import { useApiMutation } from "fetch/queryUtils";

import { SEEN_ALARM_STATUSES } from "utils/constants";

import { AlarmSeverity, EntityId } from "globalTypes";

import { AlarmProps } from "./types";

export const alarmSeverityIcon = (level: AlarmSeverity): ReactNode => {
  switch (level) {
    case AlarmSeverity.CRITICAL:
      return <ErrorOutlineIcon />;
    case AlarmSeverity.MAJOR:
      return <MajorIcon />;
    case AlarmSeverity.MINOR:
      return <MinorIcon />;
    case AlarmSeverity.WARNING:
      return <WarningAmberIcon />;
    default:
      return <QuestionMarkIcon />;
  }
};

export const alarmSeverityColours: Record<AlarmSeverity, string> = {
  CRITICAL: "error",
  MAJOR: "error",
  MINOR: "warning",
  WARNING: "warning",
  UNKNOWN: "info",
};

const AlarmCard: FC<Readonly<AlarmProps>> = ({ alarm, expandMessage }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "component.alarm.expanded",
  });
  const isSeen = SEEN_ALARM_STATUSES.includes(alarm.status);
  const navigate = useNavigate();
  const storage = useUserPreferencesStore();

  const mutationClear = useApiMutation(
    alarmApi.clearAlarm,
    t("request.cleared"),
  );

  const mutationAcknowledge = useApiMutation(
    alarmApi.acknowledgeAlarm,
    t("request.acknowledged"),
  );

  const openEntity = useCallback(
    (entity: EntityId) => {
      if (Object.keys(alarmEntityLocations).includes(entity.type)) {
        const regex = /^(.*?\/)(tenant|project)\//;
        const match = regex.exec(location.pathname);
        const basePath = match ? match[1] : "/";

        const isTenantPath = location.pathname.includes("/tenant/");

        let contextPath;
        if (isTenantPath && storage.project) {
          const projectUUID = storage.project.identifier.id;
          contextPath = `project/${projectUUID}`;
        } else {
          const regex = /\/project\/([^/]+)/;
          const match = regex.exec(location.pathname);
          const currentProjectUUID = match ? match[1] : undefined;
          contextPath = `project/${currentProjectUUID}`;
        }

        const newPath = `${basePath}${contextPath}/${alarmEntityLocations[entity.type]}/${entity.id}`;

        navigate(newPath);
      }
    },
    [storage.project, navigate],
  );

  return (
    <Box>
      <ListItem
        onClick={expandMessage}
        disableGutters
        disablePadding
        sx={{
          flexDirection: "column",
          ".Mui-selected": {
            backgroundColor: (theme) => theme.palette.background.default,
          },
          ".MuiButtonBase-root": {
            width: "100%",
          },
        }}
      >
        <ListItemButton selected={isSeen}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Box sx={{ display: "flex" }}>
              <ListItemAvatar>
                <Tooltip title={alarm.severity}>
                  <Avatar
                    status={alarmSeverityColours[alarm.severity]}
                    seen={isSeen.toString()}
                  >
                    <SvgIcon fontSize="small">
                      {alarmSeverityIcon(alarm.severity)}
                    </SvgIcon>
                  </Avatar>
                </Tooltip>
              </ListItemAvatar>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="subtitle2" color={"textPrimary"}>
                  {alarm.identifier.id}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography variant="body2" color={"textSecondary"}>
                    {t("type") + ": " + alarm.type}
                  </Typography>
                  <Typography variant="body2" color={"textSecondary"}>
                    {t("severity") + ": " + alarm.severity}
                  </Typography>
                  <Typography variant="body2" color={"textSecondary"}>
                    {t("status") + ": " + alarm.status}
                  </Typography>
                  <Typography variant="body2" color={"textSecondary"}>
                    {t("started") + ": " + alarm.events.started}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      maxWidth: 23,
                      justifyContent: "space-between",
                    }}
                  >
                    <ResourceGuard component-id="alarmsPage.alarmsTable.acknowledgeAlarm">
                      <Tooltip arrow title={t("buttons.acknowledge")}>
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            mutationAcknowledge.mutate(alarm.identifier.id);
                          }}
                        >
                          <SvgIcon>
                            <AcknowledgeAlarmIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    </ResourceGuard>
                    <ResourceGuard component-id="alarmsPage.alarmsTable.clearAlarm">
                      <Tooltip arrow title={t("buttons.clear")}>
                        <IconButton
                          onClick={() => {
                            mutationClear.mutate(alarm.identifier.id);
                          }}
                          color="secondary"
                        >
                          <NotificationsOffOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </ResourceGuard>
                    <Tooltip arrow title={t("buttons.trigger")}>
                      <IconButton onClick={() => openEntity(alarm.trigger)}>
                        <ReadMore />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </ListItemButton>
      </ListItem>
    </Box>
  );
};

export default AlarmCard;

const Avatar = styled(MuiAvatar)<ColoredAvatarProps>(
  ({ theme, status, seen }) => ({
    backgroundColor: theme.palette[status].main,
    opacity: seen === "true" ? 0.5 : 1,
  }),
);
